<template>
    <div class="section-page airplanes-page">
        <transition-group
            :duration="{ leave: 0, enter: animationDuration / 2 }"
            tag="div"
            name="fade"
            class="container section-page__airplanes airplanes-page__container"
        >
            <div class="airplanes-page__header" :key="'header'">
                <h1 class="airplanes-page__title section-title">{{ $t('airplanes-page.title') }}</h1>
                <nav class="section-nav section-nav_layout_row">
                    <ul class="section-nav__list">
                        <li class="section-nav__item" v-for="item of allCategories" :key="item.id">
                            <button
                                @click="setActiveItem(item)"
                                :class="{ 'section-nav__link_active': categories[item]['active'] }"
                                class="section-nav__link section-nav__link_theme_shiro link"
                            >
                                {{ categories[item]['ru'] }}
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            <div
                class="airplanes-page__card card"
                :class="{ 'fade-in-out': isAnimating }"
                v-for="item of sortedAirplanes"
                :key="item.id"
                :style="{ 'animation-duration': animationDuration + 'ms' }"
            >
                <div class="card__img-wrapper">
                    <router-link
                        :to="{ name: 'Airplane', params: { lang: $i18n.locale, airplaneName: item.img } }"
                        class="card__img-inner"
                    >
                        <img
                            class="card__img"
                            :src="require(`@/assets/images/catalog/${item.img}/preview.jpg`)"
                            :alt="item.title"
                        />
                    </router-link>
                </div>
                <h2 class="card__title">
                    <router-link :to="{ name: 'Airplane', params: { lang: $i18n.locale, airplaneName: item.img } }">{{
                        item.title
                    }}</router-link>
                </h2>
                <p class="card__description">
                    {{ item.excerptDescription['ru'] }}
                </p>
            </div>
        </transition-group>
    </div>
</template>

<script>
const airplaneData = require('./airplanes.json')

export default {
    name: 'Airplanes',
    components: {},
    data() {
        return {
            isAnimating: false,
            animationDuration: 1000,
            cachedCategory: 'all',
            categories: {
                all: {
                    ru: 'Все',
                    active: true,
                },
                light: {
                    ru: 'Лёгкие',
                    active: false,
                },
                middle: {
                    ru: 'Средние',
                    active: false,
                },
                large: {
                    ru: 'Большие',
                    active: false,
                },
                longrange: {
                    ru: 'Дальнемагистральные',
                    active: false,
                },
            },
            airplanes: airplaneData,
        }
    },
    computed: {
        allCategories() {
            let categoryArr = []

            this.airplanes.forEach((airplane) => {
                categoryArr = [...categoryArr, ...airplane.categories]
            })

            return [...new Set(categoryArr)]
        },
        sortedAirplanes() {
            const category = this.getActiveCategory

            return this.airplanes.filter((item) => {
                return item.categories.includes(category)
            })
        },
        getActiveCategory() {
            const activeCategory = Object.entries(this.categories).find((item) => item[1].active)

            if (!activeCategory) {
                return this.cachedCategory
            }

            return activeCategory[0]
        },
    },
    methods: {
        setActiveItem(category) {
            if (this.isAnimating) {
                return
            }

            this.cachedCategory = this.getActiveCategory
            this.categories[this.getActiveCategory].active = false
            this.isAnimating = true

            setTimeout(() => {
                this.categories[category].active = true
            }, this.animationDuration / 2)
            setTimeout(() => {
                this.isAnimating = false
            }, this.animationDuration)
        },
    },
}
</script>

<style scoped>
.fade-enter-active {
    animation: fade-in 500ms ease-in-out forwards !important;
}
.fade-in-out {
    animation: fade-in-out ease-in-out forwards;
}
@keyframes fade-in-out {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
